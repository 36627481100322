import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import { Flex, Box } from '../elements';
import website from '../../config/website';
import { linkResolver } from '../utils/linkResolver';

const Ul = styled.ul`
  ${props => props.theme.devices.desktop} {
    column-count: 3;
    width: 100%;
    max-width: 700px;
  }
`;

const ClientList = ({
  data: {
    prismicClientList: { data: client_list },
  },
  pageContext: { breadcrumb },
  location,
}) => {  
  const desc = client_list.meta_description || client_list.title.text;
  const title = client_list.meta_title || client_list.title.text;
  return (
    <Layout location={location} breadcrumb={breadcrumb}>
      <SEO
        title={`${title} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={desc}
        node={client_list}
      />
      <Box m={['0 12px', '0 12px', 0, 0]}>
        <h1>{client_list.title.text}</h1>
        <Flex flexDirection="row" flexWrap="wrap">
          <Ul>
            {client_list.list.map(item => {
                return (
                  <li key={item.client.document.uid}>
                    <Link to={linkResolver(item.client)}>{item.client.document.data.name}</Link>
                  </li>
                );
              })}
          </Ul>
        </Flex>
      </Box>
    </Layout>
  );
};

export default ClientList;

ClientList.propTypes = {
  data: PropTypes.shape({
    prismicClientList: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query ClientListBySlug($uid: String!) {
    prismicClientList(uid: {eq: $uid}, lang: {eq: "en-us"}) {
      data {
        meta_description
        meta_title
        title {
          text
        }
        list {
          client {
            document {
              __typename
              ... on PrismicClient {
                id
                data {
                  name
                }
                uid
              }
            }
          }
        }
      }
    }    
  }
`;
